import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext(null);

export function useAuth() {
  return useContext(AuthContext);
}

export const getCsrfToken = () => {
  const csrfTokenCookie = document.cookie
    .split('; ')
    .find((cookie) => cookie.trim().startsWith('csrftoken='));
  return csrfTokenCookie ? csrfTokenCookie.split('=')[1] : null;
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(undefined); // Initialize user as undefined
  const [token, setToken] = useState(localStorage.getItem('jwtToken')); // Get JWT from local storage
  const [loading, setLoading] = useState(true); // Add loading state

  const validateSession = () => {
    console.log('Checking authentication session...');
    fetch('/api/validate_session/', {
      method: 'GET',
      credentials: 'include', // This ensures cookies are included
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        const csrfToken = response.headers.get('X-CSRFToken'); // Capture CSRF from response headers
        if (csrfToken) {
          document.cookie = `csrftoken=${csrfToken}; path=/`; // Manually set the CSRF token in cookies
        }
        if (!response.ok) {
          throw new Error('Network response was not ok: ' + response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        console.log('Session validation data:', data);
        if (data.isAuthenticated) {
          setUser(data.user);
          setToken(data.jwt); // Update token
          localStorage.setItem('jwtToken', data.jwt); // Store new token
        } else {
          setUser(null); // Set user to null if not authenticated
        }
        setLoading(false); // Set loading to false after check
      })
      .catch((error) => {
        console.error('Error verifying auth:', error);
        setUser(null);
        setToken(null);
        setLoading(false); // Ensure loading is false on error
      });
  };

  const reauthenticateWithJWT = () => {
    if (token) {
      fetch(`/api/reauthenticate_with_jwt/?token=${encodeURIComponent(token)}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.isAuthenticated) {
            setUser(data.user);
          } else {
            setUser(null);
            setToken(null);
            localStorage.removeItem('jwtToken'); // Clear token if not valid
          }
          setLoading(false); // Ensure loading is false after re-authentication
        })
        .catch((error) => {
          console.error('JWT re-authentication failed:', error);
          setUser(null);
          setToken(null);
          localStorage.removeItem('jwtToken');
          setLoading(false); // Ensure loading is false on error
        });
    } else {
      setUser(null); // Set user to null if no token is available
      setLoading(false); // Set loading to false if no token
    }
  };

  useEffect(() => {
    validateSession();
  }, []); // Run once on component mount

  return (
    <AuthContext.Provider value={{ user, setUser, token, setToken, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;