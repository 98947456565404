import React, { useState, useEffect, useRef } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import { useSidebar } from '../Sidebar/SidebarContext';
import './Chat.css';

import { ReactComponent as ChatIcon } from '../../assets/images/sidebar-svgrepo-com.svg';
import { ReactComponent as SettingsIcon } from '../../assets/images/new-svgrepo-com.svg';
import openaiLogo from '../../assets/images/openai-logo.svg';
import claudeIcon from '../../assets/images/claude-icon.svg';
import geminiIcon from '../../assets/images/gemini-icon.svg';
import geminiProIcon from '../../assets/images/gemini-pro-icon.svg';

function Chat() {
  const { setSidebarContent } = useSidebar();
  const [inputText, setInputText] = useState('');
  const [selectedModel, setSelectedModel] = useState('GPT-4o mini');
  const [showModelDropdown, setShowModelDropdown] = useState(false);
  const [tooltipTimeout, setTooltipTimeout] = useState(null);
  const [showSidebarTooltip, setShowSidebarTooltip] = useState(false);
  const [showSidebarChatTooltip, setShowSidebarChatTooltip] = useState(false);
  const [showTopChatTooltip, setShowTopChatTooltip] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(
    JSON.parse(localStorage.getItem('sidebarVisible')) ?? true
  );
  const dropdownRef = useRef(null);

  const models = [
    {
      name: 'GPT-4o mini',
      description: 'Optimized for minimal latency, suitable for quick tasks.',
      icon: openaiLogo,
      iconClass: 'openai-icon',
    },
    {
      name: 'GPT-4o',
      description: 'Standard version of GPT-4 with comprehensive capabilities.',
      icon: openaiLogo,
      iconClass: 'openai-pro-icon',
    },
    {
      name: 'Claude 3 Haiku',
      description: 'Specialized for concise, poetic outputs.',
      icon: claudeIcon,
      iconClass: 'claude-haiku-icon',
    },
    {
      name: 'Claude 3.5 Sonnet',
      description: 'Enhanced Claude model for detailed responses.',
      icon: claudeIcon,
      iconClass: 'claude-sonnet-icon',
    },
    {
      name: 'Gemini 1.5 Flash',
      description: 'High-speed generation with light moderation.',
      icon: geminiIcon,
      iconClass: 'gemini-icon',
    },
    {
      name: 'Gemini 1.5 Pro',
      description: 'Full-featured generation with advanced capabilities.',
      icon: geminiProIcon,
      iconClass: 'gemini-icon',
    },
  ];

  // Save sidebar visibility to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('sidebarVisible', JSON.stringify(isSidebarVisible));
    if (isSidebarVisible) {
      setShowSidebarTooltip(false); // Reset "Close Sidebar" tooltip state
    } else {
      setShowSidebarTooltip(false); // Reset "Open Sidebar" tooltip state
    }
  }, [isSidebarVisible]);

  useEffect(() => {
    const logoContainer = document.getElementById('studio-logo-container');
    if (logoContainer) {
      logoContainer.classList.add('studio-logo-hidden');
    }

    if (isSidebarVisible) {
      setSidebarContent(
        <div className="sidebar-icon-container">
          <div
            className="sidebar-close-icon-container"
            onMouseEnter={() => {
              clearTimeout(tooltipTimeout);
              setShowSidebarTooltip(true);
            }}
            onMouseLeave={() => {
              const timeout = setTimeout(() => setShowSidebarTooltip(false), 0);
              setTooltipTimeout(timeout);
            }}
            onClick={() => setIsSidebarVisible(false)}
          >
            <ChatIcon className="sidebar-icon chat-svg-icon" />
            {showSidebarTooltip && (
              <div className="sidebar-tooltip fade-in">Close Sidebar</div>
            )}
          </div>

          <div
            className="sidebar-chat-icon-container"
            onMouseEnter={() => setShowSidebarChatTooltip(true)}
            onMouseLeave={() => setShowSidebarChatTooltip(false)}
          >
            <SettingsIcon className="sidebar-icon settings-svg-icon" />
            {showSidebarChatTooltip && (
              <div className="chat-tooltip fade-in">New Chat</div>
            )}
          </div>
        </div>
      );
    } else {
      setSidebarContent('');
    }

    return () => {
      if (logoContainer) {
        logoContainer.classList.add('studio-logo-hidden');
      }
      setSidebarContent('');
      clearTimeout(tooltipTimeout);
    };
  }, [isSidebarVisible, setSidebarContent, showSidebarTooltip, tooltipTimeout, showSidebarChatTooltip]);

  const handleInputChange = (event) => {
    setInputText(event.target.value);
    if (event.target.value !== '') {
      clearTimeout(tooltipTimeout);
    }
  };

  const handleModelSelect = (modelName, event) => {
    event.stopPropagation();
    setSelectedModel(modelName);
    setShowModelDropdown(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowModelDropdown(false);
      }
    }

    if (showModelDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showModelDropdown]);

  return (
    <div className="chat-container" style={{ transition: 'all 0.3s ease' }}>
      <div
        style={{
          width: isSidebarVisible ? '240px' : '0px',
          overflow: 'hidden',
          transition: 'width 0.3s ease',
        }}
      >
        {isSidebarVisible && <Sidebar />}
      </div>
      <div
        className="chat-content"
        style={{
          flexGrow: 1,
          transition: 'width 0.3s ease',
        }}
      >
        {/* Top Chat Options */}
        <div className="top-chat-options">
          {!isSidebarVisible && (
            <>
              <div
                className="sidebar-open-icon-container"
                onMouseEnter={() => setShowSidebarTooltip(true)}
                onMouseLeave={() => setShowSidebarTooltip(false)}
                onClick={() => setIsSidebarVisible(true)}
              >
                <ChatIcon className="sidebar-icon chat-svg-icon" />
                {showSidebarTooltip && (
                  <div className="sidebar-tooltip fade-in">Open Sidebar</div>
                )}
              </div>

              <div
                className="sidebar-chat-icon-container"
                onMouseEnter={() => setShowTopChatTooltip(true)}
                onMouseLeave={() => setShowTopChatTooltip(false)}
              >
                <SettingsIcon className="sidebar-icon settings-svg-icon" />
                {showTopChatTooltip && (
                  <div className="chat-tooltip fade-in">New Chat</div>
                )}
              </div>
            </>
          )}

          {/* Model Selection Dropdown */}
          <div
            className={`model-selection ${showModelDropdown ? 'active' : ''}`}
            onClick={() => setShowModelDropdown((prevState) => !prevState)}
            ref={dropdownRef}
          >
            <div className="model-selected">
              {selectedModel}
              <i className="bi bi-chevron-down chevron-down"></i>
            </div>
            {showModelDropdown && (
              <ul className="model-dropdown">
                {models.map((model) => (
                  <li
                    key={model.name}
                    className="model-option"
                    onClick={(event) => handleModelSelect(model.name, event)}
                  >
                    <img
                      src={model.icon}
                      alt={`${model.name} icon`}
                      className={`model-icon ${model.iconClass}`}
                    />
                    <div className="model-info">
                      <span className="model-name">{model.name}</span>
                      <span className="model-description">
                        {model.description}
                      </span>
                    </div>
                    {selectedModel === model.name && (
                      <i className="bi bi-check-circle-fill model-selected-check"></i>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>

        <div className="chat-messages">{/* Messages will go here */}</div>

        <div className="chat-input">
          <input
            type="text"
            placeholder="Message Copilot"
            value={inputText}
            onChange={handleInputChange}
            className="chat-input-field"
          />
          <div className="chat-button-wrapper">
            <button
              className={`chat-send-button ${inputText ? 'has-text' : ''}`}
              style={{ cursor: inputText ? 'pointer' : 'default' }}
            >
              <i className="chat-send-icon bi bi-arrow-up-circle-fill"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Chat;