import React from 'react';
import './Sidebar.css';
import { useSidebar } from './SidebarContext';

function Sidebar() {
  const { sidebarContent } = useSidebar();

  return (
    <div className="sidebar">
      {sidebarContent || <p>No content available</p>}
    </div>
  );
}

export default Sidebar;