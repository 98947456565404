import React, { createContext, useContext, useState } from 'react';

const SidebarContext = createContext();

export function SidebarProvider({ children }) {
  const [sidebarContent, setSidebarContent] = useState('');

  return (
    <SidebarContext.Provider value={{ sidebarContent, setSidebarContent }}>
      {children}
    </SidebarContext.Provider>
  );
}

// Custom hook for easy access to the context
export function useSidebar() {
  return useContext(SidebarContext);
}