import React, { useState, useEffect } from 'react';
import './Welcome.css'; // Importing the CSS for Welcome component
import { useAuth } from '../helpers/AuthProvider';

function Welcome() {
  const { user } = useAuth(); // Getting the logged in user info
  const [greeting, setGreeting] = useState(''); // State to hold the dynamically typed greeting text
  const [inputText, setInputText] = useState(''); // State to track the input text
  const [showTooltip, setShowTooltip] = useState(false); // State to manage tooltip visibility
  const [tooltipTimeout, setTooltipTimeout] = useState(null); // Track tooltip delay timeout
  const [iconHovered, setIconHovered] = useState(false); // Track if icon is hovered

  useEffect(() => {
    // Determine the full greeting text
    const firstName = user ? user.fullName.split(' ')[0] : 'there';
    const fullGreeting = `Hey ${firstName}, what can I help with?`;

    // Function to type out the greeting one character at a time using async/await
    const typeGreeting = async () => {
      for (let i = 0; i < fullGreeting.length; i++) {
        await new Promise((resolve) => setTimeout(resolve, 30)); // Faster typing speed
        setGreeting((prev) => prev + fullGreeting[i]); // Append the next character to the greeting
      }
      // Remove the cursor once typing is complete
      const cursorElement = document.querySelector('.cursor');
      if (cursorElement) {
        cursorElement.style.display = 'none';
      }
    };

    setGreeting(''); // Clear the greeting before starting typing
    typeGreeting(); // Start typing the greeting
  }, [user]); // Dependency ensures this runs again if user changes

  // Handler for input change
  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  // Handlers for showing and hiding tooltip with delay
  const handleMouseEnter = () => {
    if (!inputText) {
      const timeout = setTimeout(() => {
        setShowTooltip(true);
      }, 500); // Delay of 0.5 of a second
      setTooltipTimeout(timeout);
    }
  };

  const handleMouseLeave = () => {
    if (tooltipTimeout) {
      clearTimeout(tooltipTimeout);
    }
    setShowTooltip(false);
  };

  // Handle mouse enter and leave for the send icon to adjust opacity
  const handleIconMouseEnter = () => {
    if (inputText) {
      setIconHovered(true);
    }
  };

  const handleIconMouseLeave = () => {
    setIconHovered(false);
  };

  return (
    <div className="welcome-section">
      {/* Display the dynamically typed greeting with a blinking cursor */}
      <h1>{greeting}<span className="cursor">|</span></h1>
      <div className="welcome-input">
        {/* Input field for user to type messages to Copilot */}
        <input 
          type="text" 
          placeholder="Message Copilot" 
          value={inputText} 
          onChange={handleInputChange} 
        />
        <div 
          className="welcome-button-wrapper"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <button
            className="welcome-button"
            onMouseEnter={handleIconMouseEnter}
            onMouseLeave={handleIconMouseLeave}
            style={{
              cursor: inputText ? 'pointer' : 'default',
            }}
          >
            {/* Conditionally change the color and opacity of the icon based on whether there is input */}
            <i 
              className={`bi bi-arrow-up-circle-fill ${inputText ? 'icon-active' : ''} ${iconHovered && inputText ? 'icon-hovered' : ''}`}
            ></i>
          </button>
          {/* Custom tooltip */}
          {showTooltip && (
            <div className="custom-tooltip">
              Message is empty
            </div>
          )}
        </div>
      </div>
      <div className="welcome-options">
        {/* Buttons for quick actions */}
        <button className="option-button"><i className="bi bi-file-text file-text-color"></i> Summarize text</button>
        <button className="option-button"><i className="fa-solid fa-lightbulb lightbulb-color"></i> Make a plan</button>
        <button className="option-button"><i className="fa-solid fa-lightbulb lightbulb-color"></i> Brainstorm</button>
        <button className="option-button"><i className="bi bi-mortarboard mortarboard-color"></i> Get advice</button>
        <button className="option-button"><i className="bi bi-three-dots three-dots-color"></i> More</button>
      </div>
    </div>
  );
}

export default Welcome;