import React, { useEffect } from 'react';
import MenuBar from '../MenuBar/MenuBar';
import { Outlet } from 'react-router-dom';
import './StudioLayout.css';

function StudioLayout() {
  useEffect(() => {
    console.log("StudioLayout component mounted.");
    return () => {
      console.log("StudioLayout component unmounted.");
    };
  }, []);

  return (
    <div className="studio-page">
      <MenuBar />
      <div className="main-content">
        <div id="studio-logo-container" className="logo-container studio-logo-container">
          <p className="navbar-logo studio-logo-text text-gradient">Paradigm Studio</p>
          <p className="navbar-logo-x studio-logo-x">X</p>
        </div>
        <div className="studio-content">
          <Outlet />
        </div>
        <div className="footer-warning centered-footer">
          Copilot can make mistakes. Check important info.
        </div>
      </div>
    </div>
  );
}

export default StudioLayout;