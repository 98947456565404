import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './components/helpers/AuthProvider';
import { SidebarProvider } from './components/Sidebar/SidebarContext'; // Importing SidebarProvider
import LandingPage5 from './components/LandingPage5/LandingPage5';
import StudioLayout from './components/StudioLayout/StudioLayout';
import Welcome from './components/Welcome/Welcome';
import Chat from './components/Chat/Chat';
import Classroom from './components/Classroom/Classroom';
import Stats from './components/Stats/Stats';

function App() {
  useEffect(() => {
    console.log("App component mounted.");
    return () => {
      console.log("App component unmounted.");
    };
  }, []);

  console.log("Rendering App...");

  return (
    <AuthProvider>
      {console.log("AuthProvider rendered in App.")}
      <Router>
        {console.log("Router rendered in App.")}
        <Routes>
          {/* Route for the landing page */}
          <Route path="/" element={<LandingPage5 />} />
          {console.log("Route for '/' (LandingPage5) defined.")}

          {/* Studio Layout Route */}
          <Route path="/studio" element={
            <ProtectedRoute>
              <SidebarProvider> {/* Wrap the StudioLayout with SidebarProvider */}
                <StudioLayout />
              </SidebarProvider>
            </ProtectedRoute>
          }>
            {console.log("Protected route for '/studio' defined with StudioLayout and SidebarProvider.")}

            <Route index element={<Welcome />} /> {/* Default route when accessing /studio */}
            {console.log("Default route for '/studio' (Welcome) defined.")}

            <Route path="chat" element={<Chat />} />
            {console.log("Route for '/studio/chat' (Chat) defined.")}

            <Route path="classroom" element={<Classroom />} />
            {console.log("Route for '/studio/classroom' (Classroom) defined.")}

            <Route path="stats" element={<Stats />} />
            {console.log("Route for '/studio/stats' (Stats) defined.")}
          </Route>

          {/* Redirect any unknown routes */}
          <Route path="*" element={<Navigate to="/" replace />} />
          {console.log("Wildcard route for unknown paths defined.")}
        </Routes>
      </Router>
    </AuthProvider>
  );
}

// Function to protect routes by checking if the user is authenticated
function ProtectedRoute({ children }) {
  const { user, loading } = useAuth();
  console.log('ProtectedRoute:', { user, loading });

  if (loading || user === undefined) {
    console.log("ProtectedRoute is loading or user is undefined...");
    return <div>Loading...</div>;
  }

  if (!user) {
    console.log("User is not authenticated. Redirecting to '/'.");
    return <Navigate to="/" replace />;
  }

  console.log("User is authenticated. Rendering protected content.");
  return children;
}

export default App;